@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  position: relative;
  height: 100vh;
  font-family: 'Josefin Sans', sans-serif;
  overflow-x: hidden !important;
}
ul{
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}
.big-circle {
  height: 100%;
  width: 100%;
  position: relative;
  border: 3px solid #6495f2;
  border-radius: 50%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  /* animation: Rotate 20s linear infinite;
  -webkit-animation: Rotate 20s linear infinite; */
}

.main img {
  display: none;
}

.main.stop img {
  display: block;
}

.logo-main-loader {
  position: absolute;
  bottom: 30%;
  left: 60%;
}
/* center logo */
.center-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
.center-logo img {
  max-width: 200px;
}

/* .main.stop {
  position: absolute;
  left: 10%;
  top: -30%;
} */

.main.stop.animation-top {
  position: absolute;
  left: 10%;
  top: -15%;
  animation-name: move;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  transform: translate(-50%, -50%);
}

@keyframes move {
  0% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  100% {
    top: 0;
    left: 0;
    transform: translate(0, 0);
  }
}
/* keyframe animation */

@keyframes Rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes Rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes Rotate-reverse {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes Rotate-reverse {
  from {
    -webkit-transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes circle-rotate {
  from {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(405deg);
  }
}

@-webkit-keyframes circle-rotate {
  from {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
  }
  to {
    -webkit-transform: translate(-50%, -50%) rotate(405deg);
  }
}

@keyframes img-rotate {
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(-405deg);
  }
}

@-webkit-keyframes img-rotate {
  from {
    -webkit-transform: rotate(-45deg);
  }
  to {
    -webkit-transform: rotate(-405deg);
  }
}

@keyframes ball-spin-fade-loader-animation {
  50% {
    width: 50px;
    height: 50px;
  }
  100% {
    width: 50px;
    height: 50px;
  }
}
